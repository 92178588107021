<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.875 1L9.592 0.302922C9.40371 0.109261 9.1451 0 8.875 0V1ZM2.75 1V0V1ZM1 2.8H0H1ZM1 17.2H0H1ZM15 7.3H16C16 7.03966 15.8985 6.78958 15.717 6.60292L15 7.3ZM8.875 7.3H7.875C7.875 7.85228 8.32272 8.3 8.875 8.3V7.3ZM8.875 0H2.75V2H8.875V0ZM2.75 0C2.01142 0 1.30898 0.302046 0.795568 0.830129L2.22956 2.22429C2.37252 2.07724 2.56032 2 2.75 2V0ZM0.795568 0.830129C0.283084 1.35726 0 2.06634 0 2.8H2C2 2.57888 2.08566 2.37229 2.22956 2.22429L0.795568 0.830129ZM0 2.8V17.2H2V2.8H0ZM0 17.2C0 17.9337 0.283085 18.6427 0.795568 19.1699L2.22956 17.7757C2.08566 17.6277 2 17.4211 2 17.2H0ZM0.795568 19.1699C1.30898 19.698 2.01142 20 2.75 20V18C2.56032 18 2.37252 17.9228 2.22956 17.7757L0.795568 19.1699ZM2.75 20H13.25V18H2.75V20ZM13.25 20C13.9886 20 14.691 19.698 15.2044 19.1699L13.7704 17.7757C13.6275 17.9228 13.4397 18 13.25 18V20ZM15.2044 19.1699C15.7169 18.6427 16 17.9337 16 17.2H14C14 17.4211 13.9143 17.6277 13.7704 17.7757L15.2044 19.1699ZM16 17.2V7.3H14V17.2H16ZM15.717 6.60292L9.592 0.302922L8.15801 1.69708L14.283 7.99708L15.717 6.60292ZM7.875 1V7.3H9.875V1H7.875ZM8.875 8.3H15V6.3H8.875V8.3Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>