<template>
  <Modal 
    id="modal-config-termos" 
    size="lg"
    title="Configurar Termos"
    subtitle="Possível adicionar um vídeo de introdução e um texto com os termos"
    >
    <modal-upload></modal-upload>
    <div class="spaceModal overflow-auto">
      <div>
        <b-form-group
          label="Vídeo de Introdução (Opcional)"
          label-for="descricao"
        >
          <div
            class="btn-openModal"
            @click="showModalUpload"
            v-if="midiaTermosUrl === null"
          >
            <p class="text">Clique e faça o Upload do Vídeo</p>
          </div>
          <div class="btn-openModalPreview" @click="showModalUpload" v-else>
            <iframe
              v-if="mediaTypeLesson === 'panda'"
              class="imagePreviewYt animationOpacity"
              height="100%"
              width="50%"
              :src="midiaTermosUrl + '&controls=play-larg'"
              style="border:0;pointer-events: all;"
            ></iframe>
            <img
              v-else
              class="imagePreviewThumb animationOpacity"
              :src="midiaTermos"
              :alt="midiaTermos"
            />
          </div>
        </b-form-group>
        <b-form-group label="Termos" label-for="descricao">
          <Editor v-model="termosPainel" :onChange="onEditorChange" placeholder="Digite aqui a descrição do termo..."/>
        </b-form-group>
      </div>
      <div class="footer">
        <button @click="salvarDados">Salvar</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import ModalUpload from "@/components/ModalUpload.vue";
import MetaService from "@/services/resources/MetaService";
import Editor from "@/components/Editor.vue";

const serviceMeta = MetaService.build();

export default {
  components: {
    Editor,
    ModalUpload,
  },
  data() {
    return {
      termosPainel: "texto",
      midiaTermos: null,
      midiaTermosUrl: null,
      mediaTypeLesson: "",
    };
  },
  methods: {
    salvarDados() {
      var data = {
        id: `introductoryVideo`,
        value: JSON.stringify({
          url: this.midiaTermosUrl,
          thumb: this.midiaTermos,
          type: this.mediaTypeLesson,
        }),
      };
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then((resp) => {
          //console.log("meta termos update", resp);
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });

      var data1 = {
        id: `termsOfUse`,
        value: this.termosPainel,
      };
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data1)
        .then((resp) => {
          //console.log("meta termos update", resp);
          this.$root.$emit("loadOff");
          this.$root.$emit(
            "bv::hide::modal",
            "modal-config-termos",
            "#btnShow"
          );
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getMetaTermosVideo() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=introductoryVideo")
        .then((resp) => {
          //console.log("meta video termos", resp);
          var data = JSON.parse(resp.introductoryVideo);
          this.midiaTermos = data.thumb;
          this.midiaTermosUrl = data.url;
          this.mediaTypeLesson = data.type;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getMetaTermosTxt() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=termsOfUse")
        .then((resp) => {
          //console.log("meta txt termos", resp);
          this.termosPainel = resp.termsOfUse;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    showModalUpload() {
      this.$root.$emit("videotermos");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    onEditorChange(value) {
      this.termosPainel = value;
    },
  },
  mounted() {
    this.getMetaTermosTxt();
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "modal-config-termos") this.getMetaTermosTxt();
      this.getMetaTermosVideo();
    });
    this.$root.$on("midiatermoyt", (data) => {
      //console.log("data video", data);
      this.mediaTypeLesson = "youtube";
      this.midiaTermos = data.snippet.thumbnails.standard.url;
      this.midiaTermosUrl =
        "https://www.youtube.com/watch?v=" + data.id.videoId;
    });
    this.$root.$on("midiatermopanda", (data) => {
      // console.log("data video", data);
      this.mediaTypeLesson = "panda";
      this.mediaPandaId = data.id;
      this.midiaTermos = data.thumbnail;
      this.midiaTermosUrl = data.cdn_url;
    });
    this.$root.$on("midiatermovimeo", (data) => {
      //console.log("data video vimeo", data);
      this.mediaTypeLesson = "vimeo";
      this.midiaTermos = data.pictures.sizes[0].link;
      this.midiaTermosUrl = data.link;
    });
    this.$root.$on("midiatermoiframe", (data) => {
      //console.log("data video iframe", data);
      this.mediaTypeLesson = "iframe";
      this.midiaTermos = `https://app.voompplay.${process.env.VUE_URL_DOMAIN}/iframe.png`;
      this.midiaTermosUrl = data.link;
    });
  },
};
</script>

<style lang="scss" scoped>
.spaceModal {
  padding: 40px;
  margin-top: -40px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    height: 45px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    width: 251.29px;
  }
}

.btn-openModal {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  height: 55px;
  cursor: pointer;
  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor) !important;
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
}

.btn-openModalPreview {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  .imagePreviewThumb {
    height: 150px;
  }
}

.lineDiv {
  width: 100%;
  height: 0.5px;
  background: #e5e5e5;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin-top: 30px;
  margin-bottom: 30px;
}
.header {
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #333333;
    margin-bottom: 5px;
  }
  .subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #81858e;
  }
}
</style>
