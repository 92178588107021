<template>
  <div class="item">
    <slot name="icon"></slot>
    <div>
      <h6>{{ title }}</h6>
      <span>{{ descricao }}</span>
      <slot name="sub"></slot>
    </div>
    <b-form-group label="" :label-for="param">
      <b-form-checkbox
        v-model="contentValue"
        :name="param"
        @change="update"
        size="lg"
        switch
      >
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>

export default {
  props:['title', 'param', 'descricao', 'value'],
  data(){
    return{
      contentValue: this.value
    }
  },
  methods:{
    update(){
      this.$emit('update', [this.contentValue, this.param]);
    }
  }
}
</script>

<style lang="scss" scoped>
.item{
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 0px !important;
  margin-bottom: 20px;
  margin-top: 20px;
  h6{
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span{
    font-weight: 400;
    font-size: 13px;
    color: #81858E;
    margin: 0;
  }
}
.base{
  width: calc(100% - 50px);
  padding-right: 50px;
}

h1{
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line{
  width: 100%;
  height: 1px;
  background: #EDEDF0;
  margin-bottom: 25px;
}
</style>