<template>
  <div class="base">
    <div data-anima="top">
      <h1>{{ $t('settings.txt8') }}</h1>
      <div class="line"></div>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt9')"
        :value="rating_home"
        @update="update"
        param="rating_home"
        :descricao="$t('settings.txt10')"
      >
        <template v-slot:icon>
          <avaliationIcon />
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt11')"
        :value="coment_lesson"
        @update="update"
        param="coment_lesson"
        :descricao="$t('settings.txt12')"
      >
        <template v-slot:icon>
          <commentsIcon />
        </template>
        <template v-slot:sub>
          <div v-if="coment_lesson">
            <SubItem
              :title="$t('settings.txt13')"
              :value="comment_review"
              @update="update"
              param="comment_review"
              :descricao="$t('settings.txt14')"
            >
              <template v-slot:icon>
                <commentsIcon />
              </template>
            </SubItem>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt15')"
        :value="helper_data"
        @update="update"
        param="helper_data"
        :descricao="$t('settings.txt16')"
      >
        <template v-slot:icon>
          <helpIcon />
        </template>
        <template v-slot:sub>
          <div v-if="helper_data">
            <SubItem
              :title="$t('settings.txt17')"
              :value="external_help"
              @update="update"
              param="external_help"
              :descricao="$t('settings.txt18')"
            >
              <template v-slot:icon>
                <helpIcon />
              </template>
              <template v-slot:sub>
                <b-form-input
                  v-if="external_help"
                  v-model="external_help_link"
                  type="text"
                  :placeholder="$t('settings.txt19')"
                  class="inputSub"
                ></b-form-input>
                <span
                  v-if="external_help"
                  @click="saveText('external_help_link')"
                  style="cursor: pointer; text-decoration: underline;"
                  >{{ $t('settings.txt20') }}</span
                >
              </template>
            </SubItem>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt21')"
        :value="null"
        param="pais"
        :descricao="$t('settings.txt22')"
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3" style="height: 7vh;">
            <Locales />
          </div>
        </template>
      </Item>

      <Item
        v-if="renderComponent"
        :title="$t('settings.txt23')"
        :value="limit_devices_per_member"
        @update="update"
        param="limit_devices_per_member"
        :descricao="$t('settings.txt24')"
      >
        <template v-slot:icon>
          <helpIcon />
        </template>
        <template v-slot:sub>
          <div v-if="limit_devices_per_member">
            <b-form-input
              v-model="number_limit_devices_per_member"
              @change="updateNumberLimitAluno"
              style="width: 50%;"
              variant="secondary"
              type="range"
              min="1"
              max="5"
              step="1"
            ></b-form-input>
            <div>
              <span>{{$t('settings.txt25')}} {{ number_limit_devices_per_member }}
                {{
                  number_limit_devices_per_member === 1 ? $t('settings.txt26') : $t('settings.txt27')
                }}</span
              >
            </div>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt28')"
        :value="jivochat"
        @update="update"
        param="jivochat"
        :descricao="$t('settings.txt29')"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
        <template v-slot:sub>
          <div v-if="jivochat">
            <b-form-input
              v-model="id_jivochat"
              type="text"
              placeholder="ID JivoChat"
              class="inputSub"
            ></b-form-input>
            <span
              @click="saveText('id_jivochat')"
              style="cursor: pointer; text-decoration: underline;"
              >{{$t('settings.txt20')}}</span
            >
          </div>
        </template>
      </Item>

      <Item
        v-if="renderComponent"
        :title="$t('settings.txt30')"
        :value="whatsapp"
        @update="update"
        param="whatsapp"
        :descricao="$t('settings.txt31')"
      >
        <template v-slot:icon>
          <whatsAppIcon />
        </template>
        <template v-slot:sub>
          <div v-if="whatsapp">
            <div class="whatsappConfig mt-3">
              <div class="whatsappConfig-title">
                {{$t('settings.txt32')}}
              </div>
              <div class="whatsappConfig-phone">
                <div class="whatsappConfig-phone__input">
                  <VuePhoneNumberInput
                    @update="onUpdate"
                    :translations="{
                      countrySelectorLabel: $t('settings.txt33'),
                      countrySelectorError: $t('settings.txt34'),
                      phoneNumberLabel: $t('settings.txt35'),
                      example: $t('settings.txt36'),
                    }"
                    color="#000000"
                    valid-color="#000000"
                    size="lg"
                    :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
                    :countries-height="50"
                    :border-radius="5"
                    v-model="whatsapp_code"
                  />
                </div>
              </div>
              <div class="whatsappConfig-title">
                {{ $t('settings.txt37') }}
              </div>
              <div class="whatsappConfig-message">
                <b-form-textarea
                  id="textarea"
                  maxlength="150"
                  :class="{
                    'whatsappConfig-message__textArea--error':
                      whatsapp_message.length >= 150,
                  }"
                  class="whatsappConfig-message__textArea"
                  v-model="whatsapp_message"
                  :placeholder="$t('settings.txt38')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <div class="whatsappConfig-message__feedback">
                  <p
                    class="whatsappConfig-message__feedback--error"
                    v-if="whatsapp_message.length >= 150"
                  >
                    {{$t('settings.txt39')}}
                  </p>
                  <p v-else>{{$t('settings.txt40')}} {{ remaining }}</p>
                </div>
              </div>
            </div>
            <div>
              <BaseButton
                @click="saveWhatsapp"
                :disabled="valid_whatsapp === false"
                variant="link-info"
                >{{$t('settings.txt20')}}
              </BaseButton>
            </div>
          </div>
        </template>
      </Item>
      <!-- <Item
          v-if="renderComponent"
        title="Adicionar Bitrix"
        :value="bitrix"
        @update="update"
        param="bitrix"
        descricao="Se essa opção estiver ativa tera um chat para seus alunos, basta copiar os dados que estão depois de https://cdn.bitrix24.com.br/"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
        <template v-slot:sub>
          <div v-if="bitrix">
            <b-form-input
              v-model="id_bitrix"
              type="text"
              placeholder="Ex: aa12345678"
              class="inputSub"
            ></b-form-input>
            <span
              @click="saveText('id_bitrix')"
              style="cursor: pointer; text-decoration: underline"
              >Salvar</span
            >
          </div>
        </template>
      </Item> -->
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt41')"
        :value="null"
        param="protegido"
        :descricao="$t('settings.txt42')"
      >
        <template v-slot:icon>
          <docIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="showModalUploadTeste" variant="link-info"
              >{{$t('settings.txt43')}}</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt44')"
        :value="null"
        param="font"
        descricao=""
      >
        <template v-slot:icon>
          <hamburgIcon />
        </template>
        <template v-slot:sub>
          <div>
            <b-form-input
              v-model="protected_font_size"
              @change="updateFontSize"
              style="width: 50%;"
              variant="secondary"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div>
              <span> {{$t('settings.txt45')}} {{ protected_font_size }}</span>
            </div>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt46')"
        :value="null"
        param="comain"
        :descricao="$t('settings.txt47')"
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="openModalConfigDominio" variant="link-info"
              >{{$t('settings.txt48')}}</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt49')"
        :value="termsOfUseActivated"
        @update="update"
        param="termsOfUseActivated"
        :descricao="$t('settings.txt50')"
      >
        <template v-slot:icon>
          <medalIcon />
        </template>
        <template v-slot:sub>
          <div v-if="termsOfUseActivated" class="mt-3">
            <BaseButton @click="openModalConfigTermos" variant="link-info">
              {{$t('settings.txt51')}}
            </BaseButton>
          </div>
        </template>
      </Item>
      <Item
        :title="$t('settings.txt52')"
        :value="first_access_enabled"
        @update="update"
        param="first_access_enabled"
        :descricao="$t('settings.txt53')"
      >
        <template v-slot:icon>
          <firstAccessIcon />
        </template>
        <template v-slot:sub>
          <div v-if="first_access_enabled" class="mt-3">
            <BaseButton @click="openModalFirstAccess" variant="link-info">
              {{$t('settings.txt54')}}
            </BaseButton>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt55')"
        :value="limit_percetage_lesson"
        @update="update"
        param="limit_percetage_lesson"
        :descricao="$t('settings.txt56')"
      >
        <template v-slot:icon>
          <percentageIcon />
        </template>
        <template v-slot:sub>
          <div v-if="limit_percetage_lesson">
            <b-form-input
              v-model="number_limit_percetage_lesson"
              @change="updateLimitPercentageLessonAluno"
              style="width: 50%;"
              variant="secondary"
              type="range"
              min="0"
              max="100"
              step="5"
            />
            <div>
              <span>
                {{$t('settings.txt57')}}
                {{ number_limit_percetage_lesson }} %</span
              >
            </div>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt58')"
        :value="complete_lessson_next"
        @update="update"
        param="complete_lessson_next"
        :descricao="$t('settings.txt59')"
      >
        <template v-slot:icon>
          <playIcon />
        </template>
        <template v-slot:sub>
          <div v-if="complete_lessson_next"></div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt60')"
        :value="null"
        param="comain"
        :descricao="$t('settings.txt61')"
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton
              v-if="!expanded['custom-links']"
              @click="expand('custom-links')"
              variant="link-info"
            >
              {{$t('settings.txt62')}}
            </BaseButton>
          </div>
          <div v-if="expanded['custom-links']">
            <InputCustomMenuLink index="1" />
            <InputCustomMenuLink index="2" />
            <InputCustomMenuLink index="3" />
            <InputCustomMenuLink index="4" />
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt63')"
        :value="null"
        param="comain"
        :descricao="$t('settings.txt64')"
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton
              v-if="!expanded['terms-links']"
              @click="expand('terms-links')"
              variant="link-info"
            >
              {{$t('settings.txt62')}}
            </BaseButton>
          </div>
          <div v-if="expanded['terms-links']">
            <div class="flex-row gap-1 items-center">
              <b-form-input
                v-model="link_to_user_terms"
                type="text"
                :placeholder="$t('settings.txt65')"
                class="inputSub"
              />
              <ButtonPlay @click="saveText('link_to_user_terms')">
                {{$t('settings.txt20')}}
              </ButtonPlay>
            </div>
            <div class="flex-row gap-1 items-center">
              <b-form-input
                v-model="link_to_privacy_policy"
                type="text"
                :placeholder="$t('settings.txt66')"
                class="inputSub"
              />
              <ButtonPlay @click="saveText('link_to_privacy_policy')">
                {{$t('settings.txt20')}}
              </ButtonPlay>
            </div>
          </div>
        </template>
      </Item>
      <Item
        v-if="renderComponent"
        :title="$t('settings.txt81')" 
        :value="google_gtm"
        @update="update"
        param="google_gtm"
        :descricao="$t('settings.txt82')"
      >
        <template v-slot:icon>
          <googleIcon />
        </template>
        <template v-slot:sub>
          <div class="flex-row gap-2 items-center" v-if="google_gtm">
            <b-form-input
              v-model="google_gtm_code"
              type="text"
              placeholder="Código do Google Tag Manager"
              class="inputSub"
            ></b-form-input>
            <ButtonPlay @click="saveText('google_gtm_code')">
              Salvar
            </ButtonPlay>
          </div>
        </template>
      </Item>
    </div>
    <ModalUpload />
    <ModalDominio />
    <ModalTermosConfig />
    <ModalFirstAccessConfig />
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import axios from "axios";
import Cookies from "js-cookie";

import Item from "./Item.vue";
import Locales from "./Locales.vue";
import SubItem from "./SubItem.vue";

import ModalUpload from "@/components/ModalUpload.vue";
import ModalDominio from "@/components/configs/ModalDominio.vue";
import ModalTermosConfig from "@/components/configs/ModalTermosConfig.vue";
import ModalFirstAccessConfig from "@/components/configs/ModalFirstAccessConfig.vue";

import avaliationIcon from "./icons/avaliation.vue";
import commentsIcon from "./icons/comments.vue";
import helpIcon from "./icons/help.vue";
import chatIcon from "./icons/chat.vue";
import whatsAppIcon from "./icons/whatsapp.vue";
import docIcon from "./icons/doc.vue";
import hamburgIcon from "./icons/hamburg.vue";
import domainIcon from "./icons/domain.vue";
import medalIcon from "./icons/medal.vue";
import firstAccessIcon from "./icons/firstAccess.vue";
import percentageIcon from "./icons/percentage.vue";
import playIcon from "./icons/play.vue";
import googleIcon from "./icons/google.vue";
import InputCustomMenuLink from "./InputCustomMenuLink.vue";
import { mapActions } from "vuex";
import fp from "@/utils/fp";
import IconSax from "../common/IconSax.vue";
import ButtonPlay from "../common/ButtonPlay.vue";

export default {
  components: {
    VuePhoneNumberInput,
    avaliationIcon,
    commentsIcon,
    helpIcon,
    Item,
    ModalUpload,
    ModalDominio,
    ModalTermosConfig,
    ModalFirstAccessConfig,
    SubItem,
    chatIcon,
    whatsAppIcon,
    docIcon,
    hamburgIcon,
    domainIcon,
    medalIcon,
    firstAccessIcon,
    percentageIcon,
    playIcon,
    googleIcon,
    Locales,
    InputCustomMenuLink,
    IconSax,
  },
  computed: {
    remaining() {
      return 150 - this.whatsapp_message.length;
    },
},

  data() {
    return {
      renderComponent: true,
      valid_whatsapp: false,
      rating_home: false,
      coment_lesson: false,
      comment_review: false,
      helper_data: false,
      external_help: false,
      limit_devices_per_member: false,
      jivochat: false,
      whatsapp: false,
      bitrix: false,
      termsOfUseActivated: false,
      first_access_enabled: false,
      limit_percetage_lesson: false,
      complete_lessson_next: false,
      google_gtm: false,
      google_gtm_code: "",
      number_limit_devices_per_member: 1,
      id_jivochat: "",
      id_whatsapp: "",
      whatsapp_code: "",
      whatsapp_number: "",
      whatsapp_message: this.$t('settings.txt67'),
      id_bitrix: "",
      protected_font_size: 40,
      number_limit_percetage_lesson: 1,
      external_help_link: "",
      link_to_user_terms: undefined,
      link_to_privacy_policy: undefined,
      showCustomLinksInput: false,
      expanded: {},
    };
  },

  methods: {
    ...mapActions({
      actionSaveSettings: "config/actionSaveSettings",
      actionFetchSettings: "config/actionFetchSettings",
    }),
    onUpdate(value) {
      this.whatsapp_number = value.formattedNumber;
      this.valid_whatsapp = value.isValid;
    },
    async saveWhatsapp() {
      let data = {
        id: "whatsapp_number",
        value: this.whatsapp_number,
      };

      let data2 = {
        id: "whatsapp_message",
        value: this.whatsapp_message,
      };

      if (this.jivochat === false) {
        await Promise.all([serviceMeta.postID(data), serviceMeta.postID(data2)])
          .then(() => {
            this.$bvToast.toast(this.$t('settings.txt68'), {
              title: this.$t('settings.txt69'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.actionFetchSettings();
            }, 2000);
          });
      }
    },
    getKeys(key) {
      var keys = [];
      if (key) {
        keys = [key];
      } else {
        keys = [
          "link_to_privacy_policy",
          "link_to_user_terms",
          "rating_home",
          "coment_lesson",
          "comment_review",
          "helper_data",
          "external_help",
          "external_help_link",
          "limit_devices_per_member",
          "jivochat",
          "whatsapp",
          "whatsapp_number",
          "whatsapp_message",
          "bitrix",
          "id_jivochat",
          "id_bitrix",
          "termsOfUseActivated",
          "first_access_enabled",
          "limit_percetage_lesson",
          "number_limit_percetage_lesson",
          "complete_lessson_next",
          "google_gtm",
          "google_gtm_code",
          "number_limit_devices_per_member",
          "protected_font_size",
          "pais",
        ];
      }
      var result = "";
      for (let i = 0; i < keys.length; i++) {
        if (keys.length - 1 == i) {
          result += `keys[]=${keys[i]}`;
        } else {
          result += `keys[]=${keys[i]}&`;
        }
      }
      serviceMeta
        .search(result)
        .then((resp) => {
          if (resp.link_to_privacy_policy !== undefined) {
            if (resp.link_to_privacy_policy !== null) {
              this.link_to_privacy_policy = resp.link_to_privacy_policy;
            }
          }
          if (resp.link_to_user_terms !== undefined) {
            if (resp.link_to_user_terms !== null) {
              this.link_to_user_terms = resp.link_to_user_terms;
            }
          }
          if (resp.rating_home !== undefined) {
            if (resp.rating_home === null || resp.rating_home === "on") {
              this.rating_home = true;
            } else {
              this.rating_home = false;
            }
          }
          if (resp.coment_lesson !== undefined) {
            if (resp.coment_lesson === "off") {
              this.coment_lesson = false;
              this.comment_review = false;
            } else {
              this.coment_lesson = true;
            }
          }
          if (resp.comment_review !== undefined) {
            if (resp.comment_review === "off") {
              this.comment_review = false;
            } else {
              this.comment_review = true;
            }
          }
          if (resp.helper_data !== undefined) {
            if (resp.helper_data === "off") {
              this.helper_data = false;
            } else {
              this.helper_data = true;
            }
          }
          if (resp.external_help !== undefined) {
            if (resp.external_help === "on") {
              this.external_help = true;
            } else {
              this.external_help = false;
            }
          }
          if (resp.external_help_link !== undefined) {
            if (resp.external_help_link) {
              this.external_help_link = resp.external_help_link;
            }
          }
          if (resp.limit_devices_per_member !== undefined) {
            if (
              resp.limit_devices_per_member === null ||
              resp.limit_devices_per_member === "off"
            ) {
              this.limit_devices_per_member = false;
            } else {
              this.limit_devices_per_member = true;
            }
          }
          if (resp.number_limit_devices_per_member !== undefined) {
            if (resp.number_limit_devices_per_member !== null) {
              this.number_limit_devices_per_member =
                resp.number_limit_devices_per_member;
            } else {
              this.updateNumberLimitAluno(1);
            }
          }
          if (resp.jivochat !== undefined) {
            if (resp.jivochat === null || resp.jivochat === "off") {
              this.jivochat = false;
            } else {
              this.jivochat = true;
            }
          }
          if (resp.id_jivochat !== undefined) {
            if (resp.id_jivochat !== null) {
              this.id_jivochat = resp.id_jivochat;
            }
          }
          if (resp.whatsapp !== undefined) {
            if (resp.whatsapp === null || resp.whatsapp === "off") {
              this.whatsapp = false;
            } else {
              this.whatsapp = true;
            }
          }
          if (resp.whatsapp_code !== undefined) {
            if (resp.whatsapp_code !== null) {
              this.whatsapp_code = resp.whatsapp_code;
            }
          }
          if (resp.whatsapp_number !== undefined) {
            if (resp.whatsapp_number !== null) {
              this.whatsapp_code = resp.whatsapp_number;
              this.whatsapp_number = resp.whatsapp_number;
            }
          }
          if (resp.whatsapp_message !== undefined) {
            if (resp.whatsapp_message !== null) {
              this.whatsapp_message = resp.whatsapp_message;
            }
          }
          if (resp.bitrix !== undefined) {
            if (resp.bitrix === null || resp.bitrix === "off") {
              this.bitrix = false;
            } else {
              this.bitrix = true;
            }
          }
          if (resp.id_bitrix !== undefined) {
            if (resp.id_bitrix !== null) {
              this.id_bitrix = resp.id_bitrix.replace(
                "https://cdn.bitrix24.com.br/",
                ""
              );
            }
          }
          if (resp.termsOfUseActivated !== undefined) {
            if (resp.termsOfUseActivated === "on") {
              this.termsOfUseActivated = true;
            } else {
              this.termsOfUseActivated = false;
            }
          }
          if (resp.first_access_enabled !== undefined) {
            if (resp.first_access_enabled === "on") {
              this.first_access_enabled = true;
            } else {
              this.first_access_enabled = false;
            }
          }
          if (resp.limit_percetage_lesson !== undefined) {
            if (
              resp.limit_percetage_lesson === null ||
              resp.limit_percetage_lesson === "off"
            ) {
              this.limit_percetage_lesson = false;
            } else {
              this.limit_percetage_lesson = true;
            }
          }
          if (resp.number_limit_percetage_lesson !== undefined) {
            if (resp.number_limit_percetage_lesson !== null) {
              this.number_limit_percetage_lesson = parseFloat(
                resp.number_limit_percetage_lesson
              );
            } else {
              this.updateLimitPercentageLessonAluno(1);
            }
          }
          if (resp.complete_lessson_next !== undefined) {
            if (
              resp.complete_lessson_next === null ||
              resp.complete_lessson_next === "off"
            ) {
              this.complete_lessson_next = false;
            } else {
              this.complete_lessson_next = true;
            }
          }
          if (resp.google_gtm !== undefined) {
            if (
              resp.google_gtm === null ||
              resp.google_gtm === "off"
            ) {
              this.google_gtm = false;
            } else {
              this.google_gtm = true;
            }
          }
          this.google_gtm_code = resp.google_gtm_code;
          if (resp.number_limit_devices_per_member !== undefined) {
            if (resp.number_limit_devices_per_member !== null) {
              this.number_limit_devices_per_member = parseFloat(
                resp.number_limit_devices_per_member
              );
            } else {
              this.updateNumberLimitAluno(1);
            }
          }
          if (resp.protected_font_size !== undefined) {
            if (
              resp.protected_font_size === null ||
              resp.protected_font_size === ""
            ) {
              this.protected_font_size = 40;
            } else {
              this.protected_font_size = parseFloat(resp.protected_font_size);
            }
          }
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$root.$emit("getCountry");
        });
    },
    mensageUpdate() {
      this.$bvToast.toast(this.$t('settings.txt70'), {
        title: this.$t('settings.txt71'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    async update(item) {
      if (item[1] === "whatsapp") {
        if (this.jivochat === false && item[0] === true) {
          this.getKeys();
        }

        if (this.jivochat === true && item[0] === true) {
          this.$bvToast.toast(
            this.$t('settings.txt72'),
            {
              title: this.$t('settings.txt73'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.forceRender();
          return;
        }
      }

      if (item[1] === "jivochat") {
        if (this.whatsapp === false && item[0] === true) {
          this.getKeys();
        }

        if (this.whatsapp === true && item[0] === true) {
          this.$bvToast.toast(
            this.$t('settings.txt74'),
            {
              title: this.$t('settings.txt75'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.forceRender();
          return;
        }
        if (item[0] === false) {
          let container = document.querySelector("body > jdiv");
          if (container) {
            container.remove();
          }

          let jivo = document.querySelector("#jivo-iframe-container");
          if (jivo) {
            jivo.remove();
          }

          let scriptElement = document.getElementById("jivoscript");

          if (scriptElement) {
            scriptElement.parentNode.removeChild(scriptElement);
          }
        }
      }
      
      let data = {
        id: item[1],
        value: item[0] ? "on" : "off",
      };

      if(item[1] === 'coment_lesson' && item[0] === false){
        this.actionSaveSettings({
          id: 'comment_review',
          value: "off"
        });
      }


      this.actionSaveSettings(data).then(() => {
        if (item[1] === "whatsapp" && item[0] === false) {
          this.$bvToast.toast(this.$t('settings.txt76'), {
            title: this.$t('settings.txt77'),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        } else if (item[1] === "jivochat" && item[0] === false) {
          this.$bvToast.toast(this.$t('settings.txt78'), {
            title: this.$t('settings.txt79'),
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        } else {
          this.mensageUpdate();
        }

        this.getKeys(item[1]);

        if (
          item[1] === "whatsapp" &&
          this.jivochat === false &&
          item[0] === true
        ) {
          this.$nextTick(() => {
            this.whatsapp_message =
            this.$t('settings.txt80');
          });
        }
      });
    },
    saveText(text) {
      if (text) {
        let data = {
          id: text,
          value: this[text],
        };

        this.actionSaveSettings(data).then(() => {
          this.mensageUpdate();
          this.getKeys(text);
        });
      }

      if (text === "id_jivochat") {
        let data = {
          id: "id_jivochat",
          value: this.id_jivochat,
        };
        serviceMeta.postID(data).then(() => {
          this.mensageUpdate();
          this.getKeys("id_jivochat");
        });
      }
      if (text === "external_help_link") {
        let data = {
          id: "external_help_link",
          value: this.external_help_link,
        };
        serviceMeta.postID(data).then(() => {
          this.mensageUpdate();
          this.getKeys("external_help_link");
        });
      }
      if (text === "id_bitrix") {
        let data = {
          id: "id_bitrix",
          value: this.id_bitrix,
        };
        serviceMeta.postID(data).then(() => {
          this.mensageUpdate();
          this.getKeys("id_bitrix");
        });
      }
    },
    updateFontSize(number) {
      var data = {
        id: "protected_font_size",
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("protected_font_size");
      });
    },
    updateLimitPercentageLessonAluno(number) {
      let data = {
        id: "number_limit_percetage_lesson",
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("number_limit_percetage_lesson");
      });
    },
    updateNumberLimitAluno(number) {
      let data = {
        id: "number_limit_devices_per_member",
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.mensageUpdate();
        this.getKeys("number_limit_devices_per_member");
      });
    },
    showModalUploadTeste() {
      this.$root.$emit("pdfteste");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    openCustomLinks() {
      this.showCustomLinksInput = true;
    },
    openModalConfigDominio() {
      this.$root.$emit("bv::show::modal", "modal-dominio", "#btnShow");
    },
    openModalConfigTermos() {
      this.$root.$emit("bv::show::modal", "modal-config-termos", "#btnShow");
    },
    openModalFirstAccess() {
      this.$root.$emit("bv::show::modal", "modal-first-access-config", "#btnShow");
    },
    expand(section) {
      this.expanded = fp.set(section, true, this.expanded);
    },
    async forceRender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
  mounted() {
    this.getKeys();
    this.$root.$on("midiapdfteste", (data) => {
      axios({
        url:
          process.env.VUE_APP_API_HOST + `/media/${parseInt(data.id)}/preview`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Arquivo de Teste");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    });
  },
};
</script>

<style lang="scss" scoped>
[data-theme="dark"] {
  .button:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: rgb(255 255 255 / 26%);
    background-color: rgb(255 255 255 / 12%);
    border: none;
  }
}
.whatsappConfig {
  &-title {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  &-phone {
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--fontcolor);
    margin-bottom: 20px;

    &__input {
      div#MazPhoneNumberInput {
        gap: 16px;
      }
      ::v-deep input#MazPhoneNumberInput-130_phone_number,
      ::v-deep input#MazPhoneNumberInput-130_country_selector {
        border-radius: 4px !important;
      }

      ::v-deep input#MazPhoneNumberInput-178_phone_number {
        cursor: text;
      }
      ::v-deep .input-tel__label {
        font: var(--body-regular-12) !important;
        cursor: text;
        color: var(--fontcolor) !important;
      }

      ::v-deep .input-tel.lg .input-tel__input {
        cursor: text;
        caret-color: var(--fontcolor) !important;
      }

      ::v-deep .country-selector.lg .country-selector__label {
        @extend .body-regular-10;
        top: 5px;
        color: var(--fontcolor) !important;
      }

      ::v-deep .country-selector__toggle {
        top: calc(50% - 4px) !important;
      }
    }
  }
  &-message {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--fontcolor);

    &__feedback {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      font: var(--body-regular-10) !important;
      &--error {
        color: var(--Feedback-Error-500, #ff3932);
      }
    }

    &__textArea {
      &--error {
        border: 1px solid red !important;
        overflow: none !important;
      }
      padding: 10px;
      &::placeholder {
        padding: 5px 0px;
        font: var(--body-regular-12) !important;
      }
    }
  }
}
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 13px;
    color: #81858e;
    margin: 0;
  }
}

.inputSub {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 30px;
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  width: 40%;
  margin-top: 10px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
  margin-bottom: 80px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}
</style>
