<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.2 6.4L8.7547 5.56795C8.44784 5.36338 8.0533 5.34431 7.72814 5.51833C7.40298 5.69234 7.2 6.0312 7.2 6.4H8.2ZM13.6 10L14.1547 10.8321C14.4329 10.6466 14.6 10.3344 14.6 10C14.6 9.66565 14.4329 9.35342 14.1547 9.16795L13.6 10ZM8.2 13.6H7.2C7.2 13.9688 7.40298 14.3077 7.72814 14.4817C8.0533 14.6557 8.44784 14.6366 8.7547 14.432L8.2 13.6ZM18 10C18 14.4183 14.4183 18 10 18V20C15.5228 20 20 15.5228 20 10H18ZM10 18C5.58172 18 2 14.4183 2 10H0C0 15.5228 4.47715 20 10 20V18ZM2 10C2 5.58172 5.58172 2 10 2V0C4.47715 0 0 4.47715 0 10H2ZM10 2C14.4183 2 18 5.58172 18 10H20C20 4.47715 15.5228 0 10 0V2ZM7.6453 7.23205L13.0453 10.8321L14.1547 9.16795L8.7547 5.56795L7.6453 7.23205ZM13.0453 9.16795L7.6453 12.7679L8.7547 14.432L14.1547 10.8321L13.0453 9.16795ZM9.2 13.6V6.4H7.2V13.6H9.2Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>