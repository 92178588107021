<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8321 2.58211C15.2226 2.19158 15.2226 1.55842 14.8321 1.16789C14.4416 0.777369 13.8084 0.777369 13.4179 1.16789L14.8321 2.58211ZM1.16789 13.4179C0.777369 13.8084 0.777369 14.4416 1.16789 14.8321C1.55842 15.2226 2.19158 15.2226 2.58211 14.8321L1.16789 13.4179ZM13.4179 1.16789L1.16789 13.4179L2.58211 14.8321L14.8321 2.58211L13.4179 1.16789ZM4.375 3.1875C4.375 3.84334 3.84334 4.375 3.1875 4.375V6.375C4.94791 6.375 6.375 4.94791 6.375 3.1875H4.375ZM3.1875 4.375C2.53166 4.375 2 3.84334 2 3.1875H0C0 4.94791 1.42709 6.375 3.1875 6.375V4.375ZM2 3.1875C2 2.53166 2.53166 2 3.1875 2V0C1.42709 0 0 1.42709 0 3.1875H2ZM3.1875 2C3.84334 2 4.375 2.53166 4.375 3.1875H6.375C6.375 1.42709 4.94791 0 3.1875 0V2ZM14 12.8125C14 13.4683 13.4683 14 12.8125 14V16C14.5729 16 16 14.5729 16 12.8125H14ZM12.8125 14C12.1567 14 11.625 13.4683 11.625 12.8125H9.625C9.625 14.5729 11.0521 16 12.8125 16V14ZM11.625 12.8125C11.625 12.1567 12.1567 11.625 12.8125 11.625V9.625C11.0521 9.625 9.625 11.0521 9.625 12.8125H11.625ZM12.8125 11.625C13.4683 11.625 14 12.1567 14 12.8125H16C16 11.0521 14.5729 9.625 12.8125 9.625V11.625Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>