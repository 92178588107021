<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.33333 12.6826V11.6826C4.06812 11.6826 3.81376 11.788 3.62623 11.9755L4.33333 12.6826ZM1 16.0159H0C0 16.4204 0.243642 16.785 0.617317 16.9398C0.990991 17.0946 1.42111 17.009 1.70711 16.723L1 16.0159ZM2.66667 1.01593V0.0159302V1.01593ZM14.3333 1.01593V0.0159302V1.01593ZM15 11.0159C15 11.1927 14.9298 11.3623 14.8047 11.4873L16.219 12.9015C16.719 12.4015 17 11.7232 17 11.0159H15ZM14.8047 11.4873C14.6797 11.6124 14.5101 11.6826 14.3333 11.6826V13.6826C15.0406 13.6826 15.7189 13.4016 16.219 12.9015L14.8047 11.4873ZM14.3333 11.6826H4.33333V13.6826H14.3333V11.6826ZM3.62623 11.9755L0.292893 15.3088L1.70711 16.723L5.04044 13.3897L3.62623 11.9755ZM2 16.0159V2.6826H0V16.0159H2ZM2 2.6826C2 2.50579 2.07024 2.33622 2.19526 2.21119L0.781048 0.796979C0.280951 1.29708 0 1.97535 0 2.6826H2ZM2.19526 2.21119C2.32029 2.08617 2.48986 2.01593 2.66667 2.01593V0.0159302C1.95942 0.0159302 1.28115 0.296882 0.781048 0.796979L2.19526 2.21119ZM2.66667 2.01593H14.3333V0.0159302H2.66667V2.01593ZM14.3333 2.01593C14.5101 2.01593 14.6797 2.08617 14.8047 2.21119L16.219 0.796979C15.7189 0.296882 15.0406 0.0159302 14.3333 0.0159302V2.01593ZM14.8047 2.21119C14.9298 2.33622 15 2.50579 15 2.6826H17C17 1.97535 16.719 1.29708 16.219 0.796979L14.8047 2.21119ZM15 2.6826V11.0159H17V2.6826H15Z" :fill="setColor()"/>
</svg>

</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>