<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 18.25H14.99C14.58 18.25 14.24 17.91 14.24 17.5C14.24 17.09 14.58 16.75 14.99 16.75H16.5C19.12 16.75 21.25 14.62 21.25 12C21.25 9.38 19.12 7.25 16.5 7.25H15C14.59 7.25 14.25 6.91 14.25 6.5C14.25 6.09 14.58 5.75 15 5.75H16.5C19.95 5.75 22.75 8.55 22.75 12C22.75 15.45 19.95 18.25 16.5 18.25Z" :fill="setColor()"/>
        <path d="M9 18.25H7.5C4.05 18.25 1.25 15.45 1.25 12C1.25 8.55 4.05 5.75 7.5 5.75H9C9.41 5.75 9.75 6.09 9.75 6.5C9.75 6.91 9.41 7.25 9 7.25H7.5C4.88 7.25 2.75 9.38 2.75 12C2.75 14.62 4.88 16.75 7.5 16.75H9C9.41 16.75 9.75 17.09 9.75 17.5C9.75 17.91 9.41 18.25 9 18.25Z" :fill="setColor()"/>
        <path d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z" :fill="setColor()"/>
    </svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>