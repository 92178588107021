<template>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.4 16C5.84771 16 5.4 16.4477 5.4 17C5.4 17.5523 5.84771 18 6.4 18V16ZM13.6 18C14.1523 18 14.6 17.5523 14.6 17C14.6 16.4477 14.1523 16 13.6 16V18ZM11 13.4444C11 12.8922 10.5523 12.4444 10 12.4444C9.44771 12.4444 9 12.8922 9 13.4444H11ZM9 17C9 17.5523 9.44771 18 10 18C10.5523 18 11 17.5523 11 17H9ZM2.8 2H17.2V0H2.8V2ZM17.2 2C17.6536 2 18 2.3599 18 2.77778H20C20 1.23198 18.7346 0 17.2 0V2ZM18 2.77778V11.6667H20V2.77778H18ZM18 11.6667C18 12.0845 17.6536 12.4444 17.2 12.4444V14.4444C18.7346 14.4444 20 13.2125 20 11.6667H18ZM17.2 12.4444H2.8V14.4444H17.2V12.4444ZM2.8 12.4444C2.34642 12.4444 2 12.0845 2 11.6667H0C0 13.2125 1.26535 14.4444 2.8 14.4444V12.4444ZM2 11.6667V2.77778H0V11.6667H2ZM2 2.77778C2 2.3599 2.34642 2 2.8 2V0C1.26535 0 0 1.23198 0 2.77778H2ZM6.4 18H13.6V16H6.4V18ZM9 13.4444V17H11V13.4444H9Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>