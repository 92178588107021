<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3193 10.8445C13.2842 10.8277 11.972 10.1815 11.7388 10.0976C11.6436 10.0634 11.5416 10.03 11.4331 10.03C11.2559 10.03 11.1071 10.1183 10.9911 10.2918C10.86 10.4866 10.4632 10.9505 10.3406 11.0891C10.3246 11.1074 10.3027 11.1292 10.2897 11.1292C10.2779 11.1292 10.0748 11.0456 10.0133 11.0189C8.60566 10.4074 7.53718 8.93695 7.39066 8.68898C7.36974 8.65333 7.36886 8.63715 7.36869 8.63715C7.37383 8.61827 7.42117 8.57082 7.4456 8.54633C7.51707 8.47561 7.59451 8.3824 7.66943 8.29222C7.7049 8.2495 7.74044 8.20672 7.77532 8.1664C7.88403 8.03993 7.93242 7.94175 7.98852 7.82802L8.01792 7.76894C8.1549 7.49678 8.0379 7.2671 8.00009 7.19294C7.96906 7.13087 7.41497 5.79361 7.35606 5.65312C7.2144 5.3141 7.02721 5.15625 6.76709 5.15625C6.74295 5.15625 6.76709 5.15625 6.66587 5.16052C6.54262 5.16572 5.87142 5.25408 5.57465 5.44115C5.25994 5.63956 4.72754 6.27202 4.72754 7.38428C4.72754 8.38532 5.3628 9.3305 5.63555 9.68997C5.64232 9.69903 5.65477 9.71744 5.67283 9.74385C6.71736 11.2693 8.01949 12.3998 9.33951 12.9271C10.6103 13.4347 11.2121 13.4934 11.5542 13.4934H11.5543C11.698 13.4934 11.8131 13.4821 11.9146 13.4721L11.979 13.466C12.418 13.4271 13.3828 12.9272 13.6022 12.3173C13.7751 11.837 13.8207 11.3122 13.7057 11.1217C13.6269 10.9922 13.4911 10.9271 13.3193 10.8445Z"
      :fill="setColor()"
    />
    <path
      d="M9.15977 0.5C4.2848 0.5 0.318727 4.43627 0.318727 9.27459C0.318727 10.8395 0.737519 12.3713 1.53086 13.7119L0.012376 18.1912C-0.0159097 18.2747 0.00512928 18.367 0.066902 18.4299C0.111493 18.4755 0.17198 18.5 0.233753 18.5C0.257421 18.5 0.281266 18.4964 0.304525 18.489L4.97517 17.0048C6.25329 17.6877 7.69785 18.0482 9.15982 18.0482C14.0343 18.0482 18 14.1124 18 9.27459C18 4.43627 14.0343 0.5 9.15977 0.5ZM9.15977 16.2204C7.78411 16.2204 6.4517 15.8232 5.30636 15.0716C5.26785 15.0463 5.22314 15.0334 5.17814 15.0334C5.15436 15.0334 5.13051 15.037 5.10731 15.0443L2.7676 15.7881L3.5229 13.5598C3.54733 13.4876 3.53512 13.4081 3.49012 13.3466C2.61793 12.1549 2.15689 10.7469 2.15689 9.27459C2.15689 5.44416 5.29836 2.32782 9.15971 2.32782C13.0206 2.32782 16.1617 5.44416 16.1617 9.27459C16.1618 13.1046 13.0207 16.2204 9.15977 16.2204Z"
      :fill="setColor()"
    />
  </svg>
</template>

<script>
export default {
  methods: {
    setColor() {
      if (
        getComputedStyle(document.documentElement).getPropertyValue(
          "--maincolor"
        )
      ) {
        return getComputedStyle(document.documentElement).getPropertyValue(
          "--maincolor"
        );
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  },
};
</script>
