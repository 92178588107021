<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.01593L10.8967 0.573306C10.7282 0.232002 10.3806 0.0159302 10 0.0159302C9.61938 0.0159302 9.27176 0.232002 9.10329 0.573306L10 1.01593ZM12.781 6.64993L11.8843 7.09255C12.0298 7.3874 12.311 7.59186 12.6364 7.63942L12.781 6.64993ZM19 7.55893L19.6977 8.27529C19.9704 8.00976 20.0686 7.6125 19.9512 7.2505C19.8339 6.88849 19.5212 6.62448 19.1446 6.56944L19 7.55893ZM14.5 11.9419L13.8023 11.2256C13.5664 11.4553 13.4587 11.7864 13.5144 12.111L14.5 11.9419ZM15.562 18.1339L15.0965 19.019C15.4335 19.1962 15.8418 19.1667 16.1497 18.943C16.4577 18.7193 16.612 18.3401 16.5476 17.9649L15.562 18.1339ZM10 15.2089L10.4655 14.3239C10.1741 14.1706 9.82593 14.1706 9.53455 14.3239L10 15.2089ZM4.438 18.1339L3.45239 17.9649C3.38804 18.3401 3.54229 18.7193 3.85027 18.943C4.15824 19.1667 4.56654 19.1962 4.90345 19.019L4.438 18.1339ZM5.5 11.9419L6.48561 12.111C6.54127 11.7864 6.43361 11.4553 6.19773 11.2256L5.5 11.9419ZM1 7.55893L0.855372 6.56944C0.478812 6.62448 0.166132 6.88849 0.0487539 7.2505C-0.0686238 7.6125 0.029649 8.00976 0.302267 8.27529L1 7.55893ZM7.219 6.64993L7.36363 7.63942C7.68898 7.59186 7.97017 7.3874 8.11571 7.09255L7.219 6.64993ZM9.10329 1.45855L11.8843 7.09255L13.6777 6.20731L10.8967 0.573306L9.10329 1.45855ZM12.6364 7.63942L18.8554 8.54842L19.1446 6.56944L12.9256 5.66044L12.6364 7.63942ZM18.3023 6.84257L13.8023 11.2256L15.1977 12.6583L19.6977 8.27529L18.3023 6.84257ZM13.5144 12.111L14.5764 18.303L16.5476 17.9649L15.4856 11.7729L13.5144 12.111ZM16.0275 17.2489L10.4655 14.3239L9.53455 16.094L15.0965 19.019L16.0275 17.2489ZM9.53455 14.3239L3.97255 17.2489L4.90345 19.019L10.4655 16.094L9.53455 14.3239ZM5.42361 18.303L6.48561 12.111L4.51439 11.7729L3.45239 17.9649L5.42361 18.303ZM6.19773 11.2256L1.69773 6.84257L0.302267 8.27529L4.80227 12.6583L6.19773 11.2256ZM1.14463 8.54842L7.36363 7.63942L7.07437 5.66044L0.855372 6.56944L1.14463 8.54842ZM8.11571 7.09255L10.8967 1.45855L9.10329 0.573306L6.32229 6.20731L8.11571 7.09255Z"
      :fill="setColor()"
    />
  </svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>