<template>
  <div>
    <div class="item">
      <slot name="icon"></slot>
      <div>
        <h6>
          {{ title }}
          <span
            title=""
            v-if="
              param === 'login_background_image' ||
              param === 'logomarca' ||
              param === 'logo'
            "
            @click="remove"
            style="cursor: pointer; margin-left: 10px;"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#B5B9C5"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#B5B9C5"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t('item.txt1') }}
          </span>
        </h6>
        <span style="white-space: pre-line">{{ descricao }}</span>
        <slot name="sub"></slot>
      </div>
      <b-form-group label="" :label-for="param">
        <b-form-checkbox
          v-if="value !== null"
          v-model="contentValue"
          @change="update"
          :name="param"
          size="lg"
          switch
        >
        </b-form-checkbox>
      </b-form-group>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    param: {
      type: String,
    },
    descricao: {
      type: String,
    },
    value: {},
  },
  data() {
    return {
      contentValue: this.value,
    };
  },
  watch: {
    value() {
      this.contentValue = this.value;
    },
  },
  methods: {
    update() {
      this.$emit("update", [this.contentValue, this.param]);
    },
    remove() {
      this.$emit("remove", this.param);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #81858e;
    margin: 0;
  }
}
.base {
  width: calc(100% - 50px);
  padding-right: 50px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}
</style>
