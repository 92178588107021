<template>
  <div class="mb-3">
    <div class="mb-5 flex text-right">
      <div class="side-left width100" :class="{ width100: getIsMobile }">
        <BaseButton @click="openModalCreateWebhook" variant="primary">{{ $t('webhooks.add') }}</BaseButton>
      </div>
    </div>
    <div v-if="!loading && lista.length" class="lista-entrega">
      <div class="top">
        <h5 class="">• Webhook</h5>
        <a href="#" @click.prevent="showList = !showList">{{
          showList ? "ocultar" : "mostrar"
        }}</a>
      </div>
      <ul class="mt-3 opcoes-entrega" v-show="showList">
        <li
          v-for="(item, index) in lista"
          :key="item.name + item.id"
          data-anima="bottom"
        >
          <div>
            <img src="@/assets/icons/webhook-icon.svg" class="icon-file" />
            <div class="info-list">
              <span>
                #{{ item.id }} - {{ item.name }}
                {{ item.sub_trigger ? `(${item.sub_trigger})` : "" }}
                <p data-anima="bottom" class="url_webhook_text ativo">
                  {{
                    item.url.length > maxLength
                      ? item.url.substr(0, maxLength) + "..."
                      : item.url
                  }}
                </p>
                <input
                  v-model="url_edit"
                  :id="'input-edit-' + item.id"
                  class="input-edit-webhook"
                  data-anima="top"
                />
              </span>
            </div>
          </div>
          <div class="acoes-webhook">
            <img
              data-anima="bottom"
              :id="'btn-edit-' + item.id"
              src="@/assets/icons/salvar.svg"
              class="icon-lixeira btn-edit-web"
              @click="saveEditWebhook(item)"
            />
            <b-tooltip
              :target="'btn-edit-' + item.id"
              title="Salvar"
              placement="top"
            />
            <img
              src="@/assets/icons/editar.svg"
              class="icon-lixeira"
              :id="'btn-edicao-' + item.id"
              @click="editWebhook(item)"
            />
            <b-tooltip
              :target="'btn-edicao-' + item.id"
              title="Editar"
              placement="top"
            />
            <img
              src="@/assets/icons/lixeira.svg"
              class="icon-lixeira"
              :id="'btn-remover-' + item.id"
              @click="removeWebhook(index, item.id, item.name)"
            />
            <b-tooltip
              :target="'btn-remover-' + item.id"
              title="Remover"
              placement="top"
            />
            <img
              src="@/assets/icons/teste.svg"
              class="icon-lixeira"
              :id="'btn-teste-' + item.id"
              @click="testeWebhook(item.id, item.name)"
            />
            <b-tooltip
              :target="'btn-teste-' + item.id"
              title="Enviar teste"
              placement="top"
            />
          </div>
        </li>
      </ul>
      <div v-if="!showList && lista.length" class="info-qtd">
        <p class="info-lista">
          ✨ {{ lista.length }} itens do Webhook para mostrar
        </p>
      </div>
    </div>
    <div class="container-loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <ModalWebhooks />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
// helper:
import { convertTrigger } from "@/helpers.js";
import { EventBus } from "@/main.js";

// componentes: 
import ModalWebhooks from "@/components/configs/ModalWebhooks.vue"
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  name: "ListagemWebhook",
  data() {
    return {
      loading: false,
      showList: true,
      response_webhook: {},
      lista: [],
      url_edit: null,
      maxLength: 48,
      client: {
        width: 0,
      },
    };
  },
  components: {
    ModalWebhooks,
  },
  props: {
    id_product: {
      type: Number,
    },
    integracao: {
      type: Boolean,
    },
  },


  computed: {
    ...mapGetters({
      getIsMobile: "layout/getIsMobile",
    }),
  },

  methods: {
    openModalCreateWebhook() {
      this.$root.$emit("opencreatewebhook");
      this.$root.$emit("bv::show::modal", "modal-config-webhooks", "#btnShow");
    },
    // envio de teste webhook
    testeWebhook(id, name) {
      Vue.swal({
        title: "⚡ Teste Webhook",
        text: `Deseja continuar com o envio do teste webhook do evento ${name}?`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Enviar teste",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: `/webhook/${id}`,
          };

          serviceCallback
            .update(data)
            .then((response) => {
              this.response_webhook = response;
              if (response.success) {
                this.$bvToast.toast("Teste enviado com sucesso", {
                  title: "Webhook",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              } else {
                this.$bvToast.toast(`Falha ao executar o teste do Webhook`, {
                  title: `Webhook`,
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.show("modal-response");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    // salvando a edição
    saveEditWebhook(el) {
      this.submit = true;
      this.loading = true;
      let data = el.obj;
      data.url = this.url_edit;

      serviceCallback
        .update(data)
        .then(() => {
          this.loading = false;
          this.$bvToast.toast("URL atualizada com sucesso", {
            title: "Webhook",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          // atualizando lista
          this.getCallbacks();
        })
        .catch((error) => {
          console.log(error);
          this.submit = false;
          this.loading = false;
        });
    },

    // preparando campos para edição do webhook
    editWebhook(el) {
      const btnSalvar = document.querySelector(`#btn-edit-` + el.id);

      const allBtn = document.querySelectorAll(".btn-edit-web");
      allBtn.forEach((x) => x.classList.remove("ativo"));

      const allItems = document.querySelectorAll(".input-edit-webhook");
      allItems.forEach((x) => x.classList.remove("ativo"));
      const item = document.querySelector(`#input-edit-` + el.id);

      const text = item.previousElementSibling;
      const allText = document.querySelectorAll(".url_webhook_text");
      allText.forEach((x) => x.classList.add("ativo"));

      if (item.classList.contains("ativo")) {
        item.classList.remove("ativo");
        btnSalvar.classList.remove("ativo");
      } else {
        item.classList.add("ativo");
        btnSalvar.classList.add("ativo");

        item.select();
      }
      if (!text.classList.contains("ativo")) {
        text.classList.add("ativo");
      } else text.classList.remove("ativo");

      this.url_edit = el.url;
    },
    // remove Webhook
    removeWebhook(index_lista, id, name) {
      Vue.swal({
        title: "Remover Evento ",
        text: `Deseja continuar com a remoção do #${id} - ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          let data = {
            id: id,
          };
          serviceCallback
            .destroy(data)
            .then((response) => {
              if (response == 1) {
                this.$bvToast.toast("Evento removido com sucesso!", {
                  title: "Webhook",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                // atualizando front
                this.lista.splice(index_lista, 1);
                EventBus.$emit("integracoesLista", -1);
              } else {
                this.$bvToast.toast("Houve um erro ao remover o Evento", {
                  title: "Webhook",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    // puxa todos os callbacks da g digital
    getCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "webhook",
      };
      serviceCallback
        .read("?type=webhook")
        .then((r) => {
          this.lista = [];
          [...r].reverse().forEach((item) => {
            if (item.type == "webhook") {
              this.lista.push({
                name: convertTrigger(item.trigger),
                sub_trigger: convertTrigger(item.sub_trigger),
                url: JSON.parse(item.args).url,
                id: item.id,
                obj: item,
              });
            }
          });
          EventBus.$emit("integracoesLista", this.lista.length);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    // puxando callbacks atualizadas
    this.getCallbacks();
  },
  created() {
    this.$root.$on("closemodalwebhook", () => {
      this.getCallbacks();
    });
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: #81858e;
}
.lista-entrega h5 {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  /* margin-left: 20px; */
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  /* max-height: 300px; */
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}
@media screen and (max-width: 768px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: #f7f7f7;
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: lowercase;
  font-size: 12px;
  margin-top: 3px;
  color: #81858e;
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 768px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
</style>
