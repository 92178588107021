<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5.33333C0.447715 5.33333 0 5.78105 0 6.33333C0 6.88562 0.447715 7.33333 1 7.33333V5.33333ZM17 7.33333C17.5523 7.33333 18 6.88562 18 6.33333C18 5.78105 17.5523 5.33333 17 5.33333V7.33333ZM5.33333 17C5.33333 17.5523 5.78105 18 6.33333 18C6.88562 18 7.33333 17.5523 7.33333 17H5.33333ZM7.33333 6.33333C7.33333 5.78105 6.88562 5.33333 6.33333 5.33333C5.78105 5.33333 5.33333 5.78105 5.33333 6.33333H7.33333ZM2.77778 2H15.2222V0H2.77778V2ZM15.2222 2C15.6518 2 16 2.34822 16 2.77778H18C18 1.24365 16.7563 0 15.2222 0V2ZM16 2.77778V15.2222H18V2.77778H16ZM16 15.2222C16 15.6518 15.6518 16 15.2222 16V18C16.7563 18 18 16.7563 18 15.2222H16ZM15.2222 16H2.77778V18H15.2222V16ZM2.77778 16C2.34822 16 2 15.6518 2 15.2222H0C0 16.7563 1.24365 18 2.77778 18V16ZM2 15.2222V2.77778H0V15.2222H2ZM2 2.77778C2 2.34822 2.34822 2 2.77778 2V0C1.24365 0 0 1.24365 0 2.77778H2ZM1 7.33333H17V5.33333H1V7.33333ZM7.33333 17V6.33333H5.33333V17H7.33333Z" :fill="setColor()"/>
</svg>

</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>