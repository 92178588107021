<template>
  <b-modal id="modal-config-webhooks" hide-footer size="lg">
    <div>
      <h4 class="title-inter">{{ $t('webhooks.title1') }}</h4>
      <div class="d-flex justify-content-end">
        <div>
          <BaseButton variant="primary" class="link-documentacao" @click="openDocumentacaoWebHook">{{ $t('webhooks.txt1') }}
          </BaseButton>
        </div>
      </div>
      <b-form novalidate @submit.prevent="adicionarWebhook">
        <h4 class="title-inter">{{ $t('webhooks.txt2') }}</h4>
        <div class="grid-events">
          <b-form-group label="" label-for="check">
            <b-form-checkbox size="md" switch class="check-box mb-2" v-for="(op, index) in events.options_sales"
              :name="`check-${index}`" :id="`check-${index}`" v-model="selectEvent[index]" :key="op.value"
              @change="triggerChanged(op.value)">
              <p class="info-checkbox">{{ op.text }}</p>
              <div v-show="op.value == 'inactivityTime' && selectEvent[1]" data-anima="top" class="mt-3 mb-3">
                <b-form-checkbox size="md" switch v-for="(sub, index) in events.options_inactivityTime"
                  class="check-box mb-2" :name="`check-inactive-${index}`" :id="`check-inactive-${index}`"
                  :key="sub.value" v-model="selectEventInactive[index]"
                  @change="subTriggerChanged(sub.value, 'inactivityTime')">
                  <p class="info-checkbox">{{ sub.text }}</p>
                </b-form-checkbox>
              </div>
              <div v-show="op.value == 'courseProgress' && selectEvent[5]" data-anima="top" class="mt-3 mb-3">
                <b-form-checkbox size="md" switch v-for="(sub, index) in events.options_courseProgress"
                  class="check-box mb-2" :name="`check-waiting-${index}`" :id="`check-waiting-${index}`" :key="sub.value"
                  v-model="selectEventWaiting[index]" @change="
                    subTriggerChanged(sub.value, 'courseProgress')
                    ">
                  <p class="info-checkbox">{{ sub.text }}</p>
                </b-form-checkbox>
              </div>
            </b-form-checkbox>
          </b-form-group>

        </div>

        <b-form-group :label=" $t('webhooks.txt3')" label-for="url_webhook">
          <b-form-input v-validate="{ url: { require_protocol: true } }" name="url_webhook" id="url_webhook"
            placeholder="https://plataforma.com/api" type="text" v-model="events.url" autocomplete="off"
            @v-on:keyup.enter="adicionarWebhook" />
          <b-form-invalid-feedback :state="!errors.has('url_webhook')">
            {{ $t('webhooks.txt4') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
    <BaseButton variant="primary" class="float-right" :disabled="loading" @click="adicionarWebhook">
      {{ $t('webhooks.save') }}
    </BaseButton>
  </b-modal>
</template>
  
<script>
// services:
import CallbackService from "@/services/resources/CallbackService";
const callbackService = CallbackService.build();
const eventsInitialState = {
  trigger: [],
  sub_trigger: [],
  sub_trigger2: [],
  url: "",
  callback: "executarWebhook",
  options_sales: [
    {
      value: "classEndDate",
      text: "Data de Conclusão de Aula",
    },
    {
      value: "inactivityTime",
      text: "Tempo de Inatividade",
    },
    {
      value: "moduleEndDate",
      text: "Data de Conclusão do Módulo",
    },
    {
      value: "courseEndDate",
      text: "Data de Conclusão do Curso",
    },
    {
      value: "certificateDate",
      text: "Emissão do Certificado",
    },
    {
      value: "courseProgress",
      text: "Progresso do Curso",
    },
  ],
  options_inactivityTime: [
    {
      value: "1DayOfInactivity",
      text: "1 Dia",
    },
    {
      value: "5DaysOfInactivity",
      text: "5 Dias",
    },
    {
      value: "10DaysOfInactivity",
      text: "10 Dias",
    },
    {
      value: "20DaysOfInactivity",
      text: "20 Dia",
    },
    {
      value: "30DaysOfInactivity",
      text: "30 Dias",
    },
    {
      value: "60DaysOfInactivity",
      text: "60 Dias",
    },
    {
      value: "90DaysOfInactivity",
      text: "90 Dias",
    },
  ],
  options_courseProgress: [
    {
      value: "25Progress",
      text: "Concluiu 25%",
    },
    {
      value: "50Progress",
      text: "Concluiu 50%",
    },
    {
      value: "75Progress",
      text: "Concluiu 75%",
    },
    {
      value: "100Progress",
      text: "Concluiu 100%",
    }
  ]
}
export default {
  data() {
    return {
      loading: false,
      submit: false,
      webhook: {
        user_id: "22",
        type: "webhook",
        object: [],
      },
      selectEvent: [],
      selectEventInactive: [],
      selectEventWaiting: [],
      init_click: false,
      events: eventsInitialState
    };
  },
  mounted() {
    this.events.options_sales.forEach(option => {
      option.text = this.$t(`webhooks.${option.value}`);
    });

    this.events.options_inactivityTime.forEach(option => {
      option.text = this.$t(`webhooks.${option.value}`);
    });

    this.events.options_courseProgress.forEach(option => {
      option.text = this.$t(`webhooks.${option.value}`);
    });
  },
  methods: {
    cancel() {
      this.$bvModal.hide("modal-config-webhooks");
    },
    triggerChanged(v) {
      const include = this.events.trigger.includes(v);

      if (v == "inactivityTime") {
        if (!include) {
          document.querySelector(`#check-inactive-0`).click();
        } else {
          this.events.options_inactivityTime.forEach((_, index) => {
            if (this.selectEventInactive[index]) {
              document.querySelector(`#check-inactive-${index}`).click();
            }
          });
        }
      } else if (v == "courseProgress") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.events.options_courseProgress.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.events.trigger.splice(this.events.trigger.indexOf(v), 1);
      } else {
        this.events.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "inactivityTime") {
        const include = this.events.sub_trigger.includes(v);

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      } else {
        const include = this.events.sub_trigger2.includes(v);

        if (include) {
          this.events.sub_trigger2.splice(
            this.events.sub_trigger2.indexOf(v),
            1
          );
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    openDocumentacaoWebHook() {
      window.open("https://www.voompcreators.com.br/termos-e-documentacoes/");
    },
    resetWebHook() {
      this.webhook = {
        user_id: "",
        type: "webhook",
        object: [],
      };
      this.events = eventsInitialState
      this.events.trigger = [];
      this.events.sub_trigger = [];
      this.events.sub_trigger2 = [];
    },
    createWebhook(webhook) {
      let data = webhook;
      callbackService
        .create(data)
        .then(() => {
          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarWebhook(e) {
      e?.preventDefault();
      this.submit = true;
      if (this.events.trigger.length && this.events.url) {
        this.$validator.validate("url_webhook").then((result) => {
          if (result) {
            this.loading = true;
            document.querySelector("#url_webhook").classList.remove("erro");
            [...this.events.trigger].forEach((item) => {
              if (item !== "inactivityTime" && item !== "courseProgress")
                this.webhook.object.push({
                  trigger: item,
                  type: "webhook",
                  callback: "executarWebhook",
                  url: this.events.url,
                });
            });

            if (this.events.trigger.includes("inactivityTime") && this.events.sub_trigger.length) {
              [...this.events.sub_trigger].forEach((item) => {
                this.webhook.object.push({
                  trigger: "inactivityTime",
                  type: "webhook",
                  sub_trigger: item,
                  callback: "executarWebhook",
                  url: this.events.url,
                });
              });
            }

            if (this.events.trigger.includes("courseProgress") && this.events.sub_trigger2.length) {
              [...this.events.sub_trigger2].forEach((item) => {
                this.webhook.object.push({
                  trigger: "courseProgress",
                  type: "webhook",
                  sub_trigger: item,
                  callback: "executarWebhook",
                  url: this.events.url,
                });
              });
            }

            this.webhook.object.forEach((item) => {
              this.createWebhook(item);
            });

            this.resetWebHook();
            this.selectEvent = [];
            this.selectEventInactive = [];
            this.selectEventWaiting = [];
            this.loading = false;
            this.submit = false;

            this.$bvToast.toast(this.$t('webhooks.txt22'), {
              title: "Webhook",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            // fechando modal
            this.$bvModal.hide("modal-config-webhooks");
            //emitindo evento para o componente pai (opcoesEntrega)
            setTimeout(() => {
              this.$root.$emit("closemodalwebhook");
            }, 1000);



          } else {
            if (document.querySelector("#url_webhook")) {
              document.querySelector("#url_webhook").classList.add("erro");
              document.querySelector("#url_webhook").focus();
            }
            this.$bvToast.toast(this.$t('webhooks.txt4'), {
              title: "Webhook",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        });
      } else {
        this.$bvToast.toast(this.$t('webhooks.txt24'), {
          title: "Webhook",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
};
</script>
  
<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}

.form-group+.form-group {
  margin-top: 15px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
}

.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.newPainel {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  height: 55px;
  width: 250px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 0px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
  