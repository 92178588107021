<template>
  <b-modal id="modal-config-painel" hide-footer size="lg">
    <div class="spaceModal">
      <div class="titleModal">Configurar Projeto</div>
      <div class="spaceSelectPainel">
        <div class="spacePainels">
          <div class="spaceInputs">
            <b-form-group label="Nome do Projeto" label-for="name" id="step4">
              <b-form-input v-model="nameProject" placeholder="Nome do Projeto"></b-form-input>
            </b-form-group>
          </div>
          <div class="spaceInputs">
            <b-form-group label="Escreva uma descrição sobre o Projeto" label-for="resumo">
              <b-form-textarea id="textarea" v-model="descricaoProject"
                placeholder="Escreva uma descrição sobre o Projeto" rows="3" max-rows="6"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="header">
          <button class="newPainel" @click="saveProject">Salvar Projeto</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import SiteService from "@/services/resources/SiteService";
const serviceSite = SiteService.build();
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["type"],
  data() {
    return {
      nameProject: "",
      descricaoProject: "",
      currentSiteId: "",
    };
  },
  mounted() {
    this.$root.$on("openeditproject", (data) => {
      this.getCurrentSite();
    });
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    }),
  },
  methods: {
    getCurrentSite() {

      //console.log("get site and member", resp);
      this.currentSiteId = this.getMe.current.currentSite.id;
      this.nameProject = this.getMe.current.currentSite.name;
      this.descricaoProject = this.getMe.current.currentSite.description;

    },
    saveProject() {
      var data = {
        name: this.nameProject,
        description: this.descricaoProject,
      };

      var that = this;
      this.$root.$emit("loadOn");
      axios
        .post(
          process.env.VUE_APP_API_HOST + `/site/` + this.currentSiteId,
          data,
          {
            headers: {
              Authorization: Cookies.get("auth_greennCourse"),
            },
          }
        )
        .then(function (resp) {
          //console.log("update project", resp);
          that.$root.$emit(
            "bv::hide::modal",
            "modal-config-painel",
            "#btnShow"
          );
          that.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err.response);
          var error = err.response.data;
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          that.$root.$emit("loadOff");
        });
    },
  },
};
</script>
<style lang="scss">
#modal-config-painel {
  .textSlug {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-top: 12px;
    margin-left: 4px;
  }

  .spaceInputs {
    margin-bottom: 30px;

    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }

    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }

    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    height: 55px;
    width: 250px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-header {
    border: none !important;
  }

  .spaceModal {
    padding: 5px ​30px 0px 30px;
    margin-top: -20px;

    .logout {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }

    .logout2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }

    .titleModal {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: #333333;
    }

    .spaceSelectPainel {
      background: var(--backgroundcolor);
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 30px;
      padding: 20px 5px;

      .lineDiv {
        height: 0.5px;
        background: #ededf0;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      .flexData {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        .pointer {
          background: rgba(0, 214, 228, 0.1);
          border: 1px solid #00d6e4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }

        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          margin-left: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
