<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M18.1167 9.2224C18.075 8.7974 17.7167 8.48072 17.2917 8.48072H11C10.5417 8.48072 10.1667 8.85572 10.1667 9.31406V10.7391C10.1667 11.1974 10.5417 11.5724 11 11.5724H14.7584C14.6667 12.3391 14.1667 13.4974 13.0584 14.2724C12.35 14.7641 11.4084 15.1057 10.1667 15.1057C10.1084 15.1057 10.0584 15.1057 10 15.0974C7.87504 15.0307 6.07505 13.6057 5.42505 11.6474C5.25005 11.1224 5.15004 10.5724 5.15004 9.9974C5.15004 9.4224 5.25004 8.86405 5.41671 8.34739C5.46671 8.19739 5.52504 8.04741 5.59171 7.89741C6.35838 6.17241 8.03338 4.95573 10 4.8974C10.05 4.88907 10.1084 4.88906 10.1667 4.88906C11.3584 4.88906 12.25 5.28072 12.875 5.71405C13.2 5.93905 13.6334 5.88905 13.9167 5.61405L15.075 4.48073C15.4417 4.1224 15.4084 3.51405 14.9917 3.21405C13.6667 2.23905 12.05 1.66406 10.1667 1.66406C10.1084 1.66406 10.0584 1.66407 10 1.6724C6.80838 1.73074 4.06671 3.58074 2.72504 6.25574C2.15837 7.38907 1.83337 8.65573 1.83337 9.9974C1.83337 11.3391 2.15837 12.6057 2.72504 13.7391H2.73338C4.07504 16.4141 6.81671 18.2641 10 18.3224C10.0584 18.3307 10.1084 18.3307 10.1667 18.3307C12.4167 18.3307 14.3084 17.5891 15.6834 16.3141C17.2584 14.8557 18.1667 12.7224 18.1667 10.1807C18.1667 9.8224 18.15 9.51407 18.1167 9.2224Z" 
    :fill="setColor()" 
  />
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>