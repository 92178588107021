<template>
<svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.72222 0C5.16994 0 4.72222 0.447715 4.72222 1C4.72222 1.55228 5.16994 2 5.72222 2V0ZM18 2C18.5523 2 19 1.55228 19 1C19 0.447715 18.5523 0 18 0V2ZM5.72222 5.5C5.16994 5.5 4.72222 5.94772 4.72222 6.5C4.72222 7.05228 5.16994 7.5 5.72222 7.5V5.5ZM18 7.5C18.5523 7.5 19 7.05228 19 6.5C19 5.94772 18.5523 5.5 18 5.5V7.5ZM5.72222 11C5.16994 11 4.72222 11.4477 4.72222 12C4.72222 12.5523 5.16994 13 5.72222 13V11ZM18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11V13ZM1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM1.00944 2C1.56173 2 2.00944 1.55228 2.00944 1C2.00944 0.447715 1.56173 0 1.00944 0V2ZM1 5.5C0.447715 5.5 0 5.94772 0 6.5C0 7.05228 0.447715 7.5 1 7.5V5.5ZM1.00944 7.5C1.56173 7.5 2.00944 7.05228 2.00944 6.5C2.00944 5.94772 1.56173 5.5 1.00944 5.5V7.5ZM1 11C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13V11ZM1.00944 13C1.56173 13 2.00944 12.5523 2.00944 12C2.00944 11.4477 1.56173 11 1.00944 11V13ZM5.72222 2H18V0H5.72222V2ZM5.72222 7.5H18V5.5H5.72222V7.5ZM5.72222 13H18V11H5.72222V13ZM1 2H1.00944V0H1V2ZM1 7.5H1.00944V5.5H1V7.5ZM1 13H1.00944V11H1V13Z" :fill="setColor()"/>
</svg>

</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>