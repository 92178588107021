<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 20.8529H8.24998C3.27248 20.8529 1.14581 18.7262 1.14581 13.7487V8.2487C1.14581 3.2712 3.27248 1.14453 8.24998 1.14453H13.75C18.7275 1.14453 20.8541 3.2712 20.8541 8.2487V13.7487C20.8541 18.7262 18.7275 20.8529 13.75 20.8529ZM8.24998 2.51953C4.02415 2.51953 2.52081 4.02286 2.52081 8.2487V13.7487C2.52081 17.9745 4.02415 19.4779 8.24998 19.4779H13.75C17.9758 19.4779 19.4791 17.9745 19.4791 13.7487V8.2487C19.4791 4.02286 17.9758 2.51953 13.75 2.51953H8.24998Z" :fill="setColor()"/>
    <path d="M9.86335 15.0072C9.47835 15.0072 9.12085 14.9156 8.80002 14.7322C8.06669 14.3106 7.64502 13.4489 7.64502 12.3581V9.64474C7.64502 8.56308 8.06669 7.69224 8.80002 7.27057C9.53335 6.84891 10.4867 6.91308 11.4309 7.46308L13.7867 8.81974C14.7217 9.36057 15.2625 10.1581 15.2625 11.0014C15.2625 11.8447 14.7217 12.6422 13.7867 13.1831L11.4309 14.5397C10.8992 14.8514 10.3584 15.0072 9.86335 15.0072ZM9.87252 8.37057C9.72585 8.37057 9.59752 8.39807 9.49669 8.46224C9.20335 8.63641 9.02919 9.06724 9.02919 9.64474V12.3581C9.02919 12.9356 9.19419 13.3664 9.49669 13.5406C9.79002 13.7147 10.2484 13.6414 10.7525 13.3481L13.1084 11.9914C13.6125 11.6981 13.8967 11.3406 13.8967 11.0014C13.8967 10.6622 13.6125 10.2956 13.1084 10.0114L10.7525 8.65474C10.4225 8.46224 10.12 8.37057 9.87252 8.37057Z" :fill="setColor()"/>
    </svg>
</template>



<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>
