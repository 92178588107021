<template>
<svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.97164 12.8526C5.04588 12.3053 4.66242 11.8015 4.11515 11.7273C3.56788 11.653 3.06404 12.0365 2.98979 12.5837L4.97164 12.8526ZM2.85714 21L1.86622 20.8656C1.81476 21.2449 1.98438 21.6203 2.30306 21.8325C2.62174 22.0446 3.03355 22.0561 3.36364 21.8622L2.85714 21ZM7.5 18.2727L8.00649 17.4105C7.69381 17.2268 7.30619 17.2268 6.99351 17.4105L7.5 18.2727ZM12.1429 21L11.6364 21.8622C11.9664 22.0561 12.3782 22.0446 12.6969 21.8325C13.0156 21.6204 13.1852 21.245 13.1338 20.8657L12.1429 21ZM12.0102 12.5748C11.9361 12.0275 11.4323 11.644 10.885 11.7181C10.3377 11.7923 9.95418 12.2961 10.0283 12.8434L12.0102 12.5748ZM13 7.36364C13 10.306 10.5577 12.7273 7.5 12.7273V14.7273C11.622 14.7273 15 11.4503 15 7.36364H13ZM7.5 12.7273C4.44234 12.7273 2 10.306 2 7.36364H0C0 11.4503 3.37796 14.7273 7.5 14.7273V12.7273ZM2 7.36364C2 4.42126 4.44234 2 7.5 2V0C3.37796 0 0 3.27693 0 7.36364H2ZM7.5 2C10.5577 2 13 4.42126 13 7.36364H15C15 3.27693 11.622 0 7.5 0V2ZM2.98979 12.5837L1.86622 20.8656L3.84807 21.1344L4.97164 12.8526L2.98979 12.5837ZM3.36364 21.8622L8.00649 19.135L6.99351 17.4105L2.35065 20.1378L3.36364 21.8622ZM6.99351 19.135L11.6364 21.8622L12.6494 20.1378L8.00649 17.4105L6.99351 19.135ZM13.1338 20.8657L12.0102 12.5748L10.0283 12.8434L11.1519 21.1343L13.1338 20.8657Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>