<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.7 13.7C4.25228 13.7 4.7 13.2523 4.7 12.7C4.7 12.1477 4.25228 11.7 3.7 11.7V13.7ZM1 10.9H0H1ZM2.8 1V0V1ZM10.9 1V0V1ZM11.7 3.7C11.7 4.25228 12.1477 4.7 12.7 4.7C13.2523 4.7 13.7 4.25228 13.7 3.7H11.7ZM9.1 8.3H17.2V6.3H9.1V8.3ZM17.2 8.3C17.6418 8.3 18 8.65817 18 9.1H20C20 7.5536 18.7464 6.3 17.2 6.3V8.3ZM18 9.1V17.2H20V9.1H18ZM18 17.2C18 17.6418 17.6418 18 17.2 18V20C18.7464 20 20 18.7464 20 17.2H18ZM17.2 18H9.1V20H17.2V18ZM9.1 18C8.65817 18 8.3 17.6418 8.3 17.2H6.3C6.3 18.7464 7.5536 20 9.1 20V18ZM8.3 17.2V9.1H6.3V17.2H8.3ZM8.3 9.1C8.3 8.65817 8.65817 8.3 9.1 8.3V6.3C7.5536 6.3 6.3 7.5536 6.3 9.1H8.3ZM3.7 11.7H2.8V13.7H3.7V11.7ZM2.8 11.7C2.58783 11.7 2.38434 11.6157 2.23431 11.4657L0.820101 12.8799C1.3452 13.405 2.05739 13.7 2.8 13.7V11.7ZM2.23431 11.4657C2.08429 11.3157 2 11.1122 2 10.9H0C0 11.6426 0.294999 12.3548 0.820101 12.8799L2.23431 11.4657ZM2 10.9V2.8H0V10.9H2ZM2 2.8C2 2.58783 2.08429 2.38434 2.23431 2.23431L0.820101 0.820101C0.294999 1.3452 0 2.05739 0 2.8H2ZM2.23431 2.23431C2.38434 2.08429 2.58783 2 2.8 2V0C2.05739 0 1.3452 0.294999 0.820101 0.820101L2.23431 2.23431ZM2.8 2H10.9V0H2.8V2ZM10.9 2C11.1122 2 11.3157 2.08429 11.4657 2.23431L12.8799 0.820101C12.3548 0.294999 11.6426 0 10.9 0V2ZM11.4657 2.23431C11.6157 2.38434 11.7 2.58783 11.7 2.8H13.7C13.7 2.05739 13.405 1.3452 12.8799 0.820101L11.4657 2.23431ZM11.7 2.8V3.7H13.7V2.8H11.7Z" :fill="setColor()"/>
</svg>

</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>