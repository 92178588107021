<template>
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.4 3.4C3.84772 3.4 3.4 3.84772 3.4 4.4C3.4 4.95228 3.84772 5.4 4.4 5.4V3.4ZM4.4085 5.4C4.96079 5.4 5.4085 4.95228 5.4085 4.4C5.4085 3.84772 4.96079 3.4 4.4085 3.4V5.4ZM4.4 13.6C3.84772 13.6 3.4 14.0477 3.4 14.6C3.4 15.1523 3.84772 15.6 4.4 15.6V13.6ZM4.4085 15.6C4.96079 15.6 5.4085 15.1523 5.4085 14.6C5.4085 14.0477 4.96079 13.6 4.4085 13.6V15.6ZM2.7 2H16.3V0H2.7V2ZM16.3 2C16.6866 2 17 2.3134 17 2.7H19C19 1.20883 17.7912 0 16.3 0V2ZM17 2.7V6.1H19V2.7H17ZM17 6.1C17 6.4866 16.6866 6.8 16.3 6.8V8.8C17.7912 8.8 19 7.59117 19 6.1H17ZM16.3 6.8H2.7V8.8H16.3V6.8ZM2.7 6.8C2.3134 6.8 2 6.4866 2 6.1H0C0 7.59117 1.20883 8.8 2.7 8.8V6.8ZM2 6.1V2.7H0V6.1H2ZM2 2.7C2 2.3134 2.3134 2 2.7 2V0C1.20883 0 0 1.20883 0 2.7H2ZM2.7 12.2H16.3V10.2H2.7V12.2ZM16.3 12.2C16.6866 12.2 17 12.5134 17 12.9H19C19 11.4088 17.7912 10.2 16.3 10.2V12.2ZM17 12.9V16.3H19V12.9H17ZM17 16.3C17 16.6866 16.6866 17 16.3 17V19C17.7912 19 19 17.7912 19 16.3H17ZM16.3 17H2.7V19H16.3V17ZM2.7 17C2.3134 17 2 16.6866 2 16.3H0C0 17.7912 1.20883 19 2.7 19V17ZM2 16.3V12.9H0V16.3H2ZM2 12.9C2 12.5134 2.3134 12.2 2.7 12.2V10.2C1.20883 10.2 0 11.4088 0 12.9H2ZM4.4 5.4H4.4085V3.4H4.4V5.4ZM4.4 15.6H4.4085V13.6H4.4V15.6Z" :fill="setColor()"/>
</svg>
</template>

<script>
export default {
  methods:{
    setColor(){
      if(getComputedStyle(document.documentElement).getPropertyValue('--maincolor')){
        return getComputedStyle(document.documentElement).getPropertyValue('--maincolor')
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
    this.$root.$on("changedmaincolor", () => {
      this.$forceUpdate();
    });
  }
}
</script>