import axios from 'axios'
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}/gdigital`,
  headers: {
    // 'Integration-key': 'bbef41205b3791435fbf1dfa334559f5',
    // 'Access-Control-Allow-Origin': '*',
    'Authorization': Cookies.get('auth_greennCourse'),
  }
})
export const api_gdigital = {
  get(endpoint) {
    return axiosInstance.get(endpoint)
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body)
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint)
  }
}